//=================================================
// Block
//=================================================

:not(#page-title):not(#page-content) {
  &.block {
    .title {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding-bottom: $space_lg;
        margin-bottom: 0;
        font-family: AtlantaBold;
        color: $brown-2;
      }
    }
    .title-cursive {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding-bottom: $space_lg;
        margin-bottom: 0;
        font-family: AtlantaBoldCursive;
        color: $brown-2;
      }
    }
  }
}

.description {
  font-family: AtlantaCursive;
  color: $brown-3;
  &:not([class*="-element"]) {
    &.max-width-1 {
      max-width: 638px;
      margin-left: 30.2%;

      @media #{$media_sm} {
        margin-left: 0;
      }
    }

    &.max-width-2 {
      max-width: 500px;
      margin-left: 8.4%;

      @media #{$media_sm} {
        margin-left: 0;
      }
    }
  }

  &[class*="-element"] {
    &.max-width-1 {
      [class*="-widget-wrap"],
      > [class*="-widget-container"] {
        max-width: 638px;
        margin-left: 30.2%;

        @media #{$media_sm} {
          margin-left: 0;
        }
      }
    }

    &.max-width-2 {
      [class*="-widget-wrap"],
      > [class*="-widget-container"] {
        max-width: 500px;
        margin-left: 8.4%;

        @media #{$media_sm} {
          margin-left: 0;
        }
      }
    }
  }
}

.max-width-3 {
  max-width: 638px;
}

[class*="section-height-full"] {
  > [class*="container"] {
    height: 100%;
  }
}
