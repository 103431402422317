//=================================================
// Modal
//=================================================
.shift-modal {
  padding-left: 0 !important;
  padding-right: 17px !important;
}

.modal {
  background-color: $modal_background_color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  min-width: 320px;

  .modal-dialog {
    .modal-header {
      .close {
        &.btn {
          padding: 8px;
          margin: 0 -8px 0 auto;
          font-size: 1.263rem;
        }

        .adveits-i.adv-close span {
          background-color: $modal_content_color;
        }
      }
    }
  }
}

.logged-in {
  &.admin-bar {
    .modal {
      padding-top: 32px;

      @media #{$media_lg} {
        padding-top: 46px;
      }
    }
  }
}

/*=============================
// Modal size
=============================*/
.modal-full {
  max-width: 100%;
  margin: 0;
}

/*=============================
// Modal header
=============================*/
.modal-header-top {
  position: absolute;
  z-index: 99;
  width: 100%;
}

/*=============================
// Modal body
=============================*/
.modal-body-centered {
  display: flex;
  align-items: center;
  min-height: 100%;
  padding: 0;

  &::before {
    content: "";
    display: block;
    height: 100vh;
  }
}

/*=============================
// Modal title
=============================*/
.modal-title {
  margin-bottom: 0 !important;
  @extend .after;
}

/*=============================
// Modal menu
=============================*/
#menu-modal {
  .modal-body {
    > .d-flex {
      padding-top: $header_padding_t + $header_padding_b + 1.334rem + 2.632rem;
      padding-bottom: 7.368rem;
    }

    .search-toggle {
      text-align: center;
      padding-top: 6.474rem;
    }

    .menu-img {
      position: absolute;
      width: 35%;
      margin-left: -15.8%;
      margin-top: 18px;
      min-width: 233px;
      max-width: 562px;

      img {
        margin-left: 23%;
        max-width: 100%;
        opacity: 0.1;
      }
    }
  }
}

/*=============================
// Modal search
=============================*/
#search-modal {
  .modal-body {
    form {
      width: 100%;
      max-width: 1050px;
      margin: auto;
    }
  }
}
