//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
  .logo {
    img {
      width: 12rem;
    }
  }
}

/*=============================
// Header
=============================*/
.site-header,
#menu-modal .modal-header {
  .logo {
    img {
      width: 260px;
      color: $brown-2;
    }
    @media #{$media_sm} {
      img {
        width: 180px;
      }
    }
  }
}
