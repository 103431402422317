//=================================================
// Page title
//=================================================

#page-title {
  padding-bottom: 3em;
  @media #{$media_xs} {
    padding-bottom: 0em;
  }
  .page-title-img {
    width: 100%;
    //min-width: 275px;
    //max-width: 661px;

    @media #{$media_sm} {
      position: absolute;
      min-width: 230px;
    }

    img {
      opacity: 0.6;
      width: 100%;
      height: auto;
      display: block;
    }
    @media #{$media_sm} {
      img {
        opacity: 0.6;
        height: auto;
        visibility: hidden;
      }
    }
  }

  .page-title-logo {
    width: 100%;
    //min-width: 275px;
    //max-width: 661px;
    display: none;

    @media #{$media_sm} {
      position: absolute;
      min-width: 230px;
      display: block;
    }

    img {
      opacity: 0.8;
      width: 100%;
      height: auto;
      display: block;
    }
    @media #{$media_md} {
      img {
        opacity: 0.8;
        width: 100%;
        height: auto;
      }
    }
  }

  .page-title-content {
    position: relative;
    //height: 100%;
    @media #{$media_sm} {
      height: 260px;
    }

    .page-title-description {
      background-color: transparent;
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      padding-left: 20%;
      padding-right: 20%;
      width: 100%;

      .title {
        font-family: America;
        background-color: transparent;
        padding-top: 0;
      }
      .title h1 {
        color: $brown-2;
        font-family: America;
        font-weight: 100;
        line-height: 80%;
        font-size: 7rem;

        @media #{$media_sm} {
          display: none;
        }
      }

      .description {
        font-family: AtlantaCursive;
        background-color: transparent;
        padding-top: $space_lg;
        margin-left: 20%;
        max-width: 638px;

        @media #{$media_sm} {
          margin-left: 0;
          display: none;
        }
        @media #{$media_md} {
          padding-top: $space_md;
        }
      }

      @media #{$media_md} {
        //position: static;
        //@include transform(translateY(0));
        //padding-left: 0;
        padding-left: 20%;
        padding-right: 17%;

        .title h1 {
          color: $brown-2;
          font-family: America;
          font-weight: 100;
          line-height: 80%;
          font-size: 5rem;
        }
      }
      @media #{$media_sm} {
        //position: static;
        //@include transform(translateY(0));
        //padding-left: 0;

        .title h1 {
          color: $brown-2;
          font-family: America;
          font-weight: 100;
          line-height: 80%;
          font-size: 3.5rem;
        }
      }
    }
  }

  .description-2 {
    max-width: 638px;
    padding-top: $space_lg;
    margin: 0 $space_xl 0 auto;

    @media #{$media_sm} {
      margin: 0 0 0 0;
    }
  }

  .description-3 {
    max-width: 638px;

    @media #{$media_sm} {
      padding-top: $space_lg;
    }
  }
}

/*=============================
// Single portfolio
=============================*/
.single-portfolio,
.single-adveits_portfolio {
  #page-title {
    .page-title-description {
      .description {
        margin-left: 46%;

        @media #{$media_sm} {
          margin-left: 0;
        }
      }
    }
  }
}
