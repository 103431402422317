//=================================================
// Portfolio
//=================================================

/*=============================
// Project info
=============================*/
.project-info {
  .project-info-items {
    display: flex;
    flex-wrap: wrap;
    margin: -$space_md / 2;

    @media #{$media_xl} {
      margin: -$space_md / 3.3;
    }

    .project-info-item {
      position: relative;
      flex: 0 0 50%;
      max-width: 50%;
      padding: $space_md / 2;

      @media #{$media_xl} {
        padding: $space_md / 3.3;
      }

      @media #{$media_lg} {
        flex: 0 0 100%;
        max-width: 100%;
      }

      @media #{$media_sm} {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .project-info-item-content {
        position: relative;
        text-align: center;
        height: 100%;

        .img {
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          margin-bottom: 0 !important;

          &.object-fit {
            &:before {
              padding-top: 72% !important;
            }
          }
        }

        .project-info-item-description {
          position: relative;
          z-index: 2;
          padding: $space_md 0.526rem;
          height: 100%;

          .project-info-title {
            color: $body_headline_color;
          }

          .project-info-description {
            padding-top: $space_sm;
          }
        }
      }
    }
  }
}

/*=============================
// Slider
=============================*/
.single-portfolio,
.single-adveits_portfolio {
  #single {
    .single-content {
      .description {
        margin-top: 0;
      }

      .adv-slider {
        .img {
          min-height: 300px;
          max-height: 100%;

          &.object-fit {
            &:before {
              padding-top: 56.25%;
            }
          }
        }
      }
    }
  }
}

/*=============================
// Pagination
=============================*/
.portfolio-pagination {
  .link {
    display: inline-block;

    > .d-flex {
      max-width: 500px;
      width: 100%;
    }

    .left {
      margin-right: 21%;

      @media #{$media_sm} {
        margin-right: 10%;
      }
    }

    [class*="fa-"] {
      font-size: 1.579rem;
    }

    .link-text {
      font-size: $f_size_df;
      text-transform: uppercase;
      color: $body_text_primary_color;
      font-weight: 700;
      margin-bottom: 0 !important;
    }

    .link-title {
      padding-top: $space_lg;
      line-height: 1.6;

      @include lhCrop-b(1.6, 0.7);

      &:last-child {
        @include lhCrop-a(1.6, 0.75);
      }
    }

    &.previous-link {
      text-align: left;
      padding-right: $space_lg;

      @media #{$media_sm} {
        padding-right: 0;
      }
    }

    &.next-link {
      text-align: right;
      padding-left: $space_lg;

      @media #{$media_sm} {
        padding-left: 0;
      }
    }
  }
}
